import { default as companyKmOl8cC36qMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/company.vue?macro=true";
import { default as indexJxpYUai3MLMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/index.vue?macro=true";
import { default as PUREvidenceZWiesA1zbZMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/PUREvidence.vue?macro=true";
import { default as research0ZZyxKSmCcMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/research.vue?macro=true";
import { default as synthIPDnhUsBaCsGXMeta } from "/home/runner/work/hopeai-official/hopeai-official/pages/synthIPD.vue?macro=true";
export default [
  {
    name: "company",
    path: "/company",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/company.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "PUREvidence",
    path: "/PUREvidence",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/PUREvidence.vue").then(m => m.default || m)
  },
  {
    name: "research",
    path: "/research",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/research.vue").then(m => m.default || m)
  },
  {
    name: "synthIPD",
    path: "/synthIPD",
    component: () => import("/home/runner/work/hopeai-official/hopeai-official/pages/synthIPD.vue").then(m => m.default || m)
  }
]
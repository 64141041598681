import revive_payload_client_Y6F5cNYJUw from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ci5ciocdzwllv2igr54aqb67qq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kLppG6L7Tl from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ci5ciocdzwllv2igr54aqb67qq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yNToSZOg8I from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ci5ciocdzwllv2igr54aqb67qq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_mWP8g5X6Hx from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ci5ciocdzwllv2igr54aqb67qq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_oN6oTcsp2A from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ci5ciocdzwllv2igr54aqb67qq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_aLk4vaRKkW from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ci5ciocdzwllv2igr54aqb67qq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GEgycctuqt from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ci5ciocdzwllv2igr54aqb67qq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xa3geiZrBS from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_vue@3.5.5/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/hopeai-official/hopeai-official/.nuxt/components.plugin.mjs";
import prefetch_client_6pEpqckcVY from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ci5ciocdzwllv2igr54aqb67qq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_YV71IIHCWh from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.21.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_b3PE5MzbDJ from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxtjs-naive-ui@1.0.2_magicast@0.3.5_rollup@4.21.3_vue@3.5.5/node_modules/nuxtjs-naive-ui/dist/runtime/plugin.mjs";
import templates_plugin_5e65376b_b5mEzJXB8o from "/home/runner/work/hopeai-official/hopeai-official/.nuxt/templates.plugin.5e65376b.mts";
import wow_RYu4ocOzj5 from "/home/runner/work/hopeai-official/hopeai-official/plugins/wow.ts";
export default [
  revive_payload_client_Y6F5cNYJUw,
  unhead_kLppG6L7Tl,
  router_yNToSZOg8I,
  payload_client_mWP8g5X6Hx,
  navigation_repaint_client_oN6oTcsp2A,
  check_outdated_build_client_aLk4vaRKkW,
  chunk_reload_client_GEgycctuqt,
  plugin_vue3_xa3geiZrBS,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6pEpqckcVY,
  plugin_client_YV71IIHCWh,
  plugin_b3PE5MzbDJ,
  templates_plugin_5e65376b_b5mEzJXB8o,
  wow_RYu4ocOzj5
]
import WOW from 'wow.js'

 
export default defineNuxtPlugin((nuxtApp)=>{
  const wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 0,
    mobile: true,
    live: true,
  })
  wow.init()
  nuxtApp.provide('wow', wow)
})
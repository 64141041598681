<script setup lang="ts">

import { NConfigProvider, NMessageProvider } from 'naive-ui';

useHead({
  title: 'HopeAI, AI-powered clinical development',
  meta: [
    { name: 'theme-color', content: '#0D121F' },
    { name: 'msapplication-navbutton-color', content: '#0D121F' },
    { name: 'author', content: 'HopeAI' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no' },
    { name: 'apple-mobile-web-app-title', content: 'HopeAI' },
    { name: 'application-name', content: 'HopeAI' },
    { name: 'msapplication-tap-highlight', content: 'no' },
    {
      name: 'description', content: 'We are on a mission to bring hope to patients by accelerating the clinical development of life-saving drugs through AI.'
    },
    { name: 'og:site_name', content: 'HopeAI' },
    {
      name: 'og:description', content: 'We are on a mission to bring hope to patients by accelerating the clinical development of life-saving drugs through AI.', property: 'og:description'
    },
    {
      name: 'og:title', content: 'HopeAI, AI-powered clinical development', property: 'og:title'
    },
    {
      name: 'og:image', content: 'https://hopeai.oss-us-east-1.aliyuncs.com/img/hopeai.png', property: 'og:image'
    },
    {
      name: 'og:url', content: 'https://hopeai.co', property: 'og:url'
    },
    {
      name: 'twitter:description', content: 'We are on a mission to bring hope to patients by accelerating the clinical development of life-saving drugs through AI.', property: 'twitter:description'
    },
    {
      name: 'twitter:title', content: 'HopeAI, AI-powered clinical development', property: 'twitter:title'
    },
    {
      name: 'twitter:image', content: 'https://hopeai.oss-us-east-1.aliyuncs.com/img/hopeai.png', property: 'twitter:image'
    },
    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:site', content: '@HopeAI' },
    { name: 'twitter:app:name:iphone', content: 'HopeAI' },
    { name: 'twitter:app:name:ipad', content: 'HopeAI' },
    { name: 'twitter:app:name:googleplay', content: 'HopeAI' },
    { name: 'twitter:url', content: 'https://hopeai.co' },
    {
      name: 'title', content: 'HopeAI, AI-powered clinical development'
    },
    {
      name: 'og:type', content: 'website', property: 'og:type'
    },
    {
      name: 'keywords', content: 'HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu'
    },
    {
      name: "msapplication-TileColor", content: "#603cba"
    },
    {
      name: "msapplication-TileImage", content: "/mstile-144x144.png"
    },
    {
      name: "theme-color", content: "#ffffff"
    }
  ],
  link: [
    { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
    { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
    { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
    { rel: "manifest", href: "/site.webmanifest" },
    { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#000000" },
    {
      rel: 'alternate', href: 'https://hopeai.co', hreflang: 'x-default'
    },
    {
      rel: 'alternate', href: 'https://hopeai.co', hreflang: 'en'
    },
    {
      rel: 'canonical', href: 'https://hopeai.co'
    },
    {
      rel: 'preconnect', href: 'https://hopeai.co'
    },
    {
      rel: 'preconnect', href: 'https://hopeai.co', crossorigin: 'anonymous'
    },
    {

    }
  ],
  script: [
    {
      type: 'application/ld+json',
      innerHTML: `
      { 
        "@context": "https://schema.org/", 
        "@type": "Organization", 
        "url": "https://hopeai.co/", 
        "logo":"https://hopeai.oss-us-east-1.aliyuncs.com/img/logo.jpg"     
      }
      `,
      hid: 'ldjson-schema',
    }
  ]
})
useSeoMeta({
  title: 'HopeAI, AI-powered clinical development',
  description: 'We are on a mission to bring hope to patients by accelerating the clinical development of life-saving drugs through AI.',
  ogDescription: 'We are on a mission to bring hope to patients by accelerating the clinical development of life-saving drugs through AI.',
  ogImage: 'https://hopeai.oss-us-east-1.aliyuncs.com/img/hopeai.png',
  ogUrl: 'https://hopeai.co',
  ogType: 'website',
  ogLocale: 'en_US',
  twitterTitle: 'HopeAI, AI-powered clinical development',
  twitterDescription: 'We are on a mission to bring hope to patients by accelerating the clinical development of life-saving drugs through AI.',
  twitterImage: 'https://hopeai.oss-us-east-1.aliyuncs.com/img/hopeai.png',
  twitterCard: 'summary_large_image',
})

const themeOverrides = {
  "common": {
    "borderRadius": "6px",
    "borderRadiusSmall": "2px",
    "primaryColor": "#7A47B9FF",
    "primaryColorHover": "#7C46BFFF",
    "primaryColorPressed": "#7A47B9FF",
    "primaryColorSuppl": "#7A47B9FF"
  }
}

</script>

<template>
  <div class="hopeai_home">
    <n-config-provider :theme-overrides="themeOverrides">
      <n-message-provider>
        <NuxtPage />
      </n-message-provider>
    </n-config-provider>
  </div>
</template>

<style lang="scss">
body {
  width: 100%;
  min-height: 100vh;
  background: #0D121F;
  transition: color 0.5s, background-color 0.5s;
  line-height: 1.6;
  font-family:
    -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
    Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  padding: 0;
  margin: 0;
}

.hopeai_home {
  width: 100%;
  height: 100%;
}
</style>